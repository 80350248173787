import React, { useState } from 'react';

import '../styles/Contacts.css';


const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Sending...');

        // Simulated API request (replace with your actual API logic)
        try {
            // Example of sending data to an API endpoint
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setStatus('Message sent successfully!');
                setFormData({ name: '', email: '', message: '' });
            } else {
                setStatus('Failed to send message. Please try again.');
            }
        } catch (error) {
            setStatus('An error occurred. Please try again later.');
        }
    };

    return (
      <div className="contactUs-container">
        <div className="contactUs-form-container">
          <h2>Write Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
              ></textarea>
            </div>
            <button type="submit" className="">
              Send
            </button>
          </form>
          {status && <p className="">{status}</p>}
        </div>
        <div className="contactUs-inf">
          <div className="contactUs-inf-title">
            <div className=""><h3>Contact information</h3></div>
            <div className=""><p>We're open for any suggestion or just to have a chat</p></div>
          </div>
          <div className="contactUs-inf-content">
            <div className="">icon</div>
            <div className=""><p><strong>Adress:</strong>538 Finlayson Cresent, Ottawa, ON, Canada</p></div>
          </div>
          <div className="contactUs-inf-content">
            <div className="">icon</div>
            <div className=""><p><strong>Phone:</strong>(343) 999 3025</p></div>
          </div>
          <div className="contactUs-inf-content">
            <div className="">icon</div>
            <div className=""><p><strong>Email:</strong>sawas.main.ca@gmail.com</p></div>
          </div>
        </div>
      </div>
    );
};

export default ContactForm;